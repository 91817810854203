import React, { useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Image from "../image/image";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";

const ImageSlider = ({ images, globalCaption, style }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    adaptiveHeight: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    dots: true,
    nextArrow: (
      <button aria-label="next">
        <ArrowRight32
          aria-hidden="true"
          width={48}
          height={48}
          viewBox="0 0 32 24"
        />
      </button>
    ),
    prevArrow: (
      <button aria-label="prev">
        <ArrowLeft32
          aria-hidden="true"
          width={48}
          height={48}
          viewBox="0 0 32 24"
        />
      </button>
    ),
    afterChange: (_currentSlide) => setCurrentSlide(_currentSlide),
  };

  const currentImage = images[currentSlide];

  if (images.length === 0) {
    return;
  }

  if (images.length === 1) {
    return (
      <Image
        data={images[0].entity.fieldMediaImage}
        caption={images[0].customCaption || images[0].entity.fieldCaption || globalCaption}
        credit={images[0].entity?.fieldCredit}
      />
    );
  }

  return (
    <>
      <Slider {...sliderSettings}>
        {images.map((image, i) => (
          <div key={i}>
            <Image data={image.entity?.fieldMediaImage} />
          </div>
        ))}
      </Slider>
      <div className="caption-wrap">
        <p className="small">
          {currentImage.customCaption || currentImage.entity.fieldCaption || globalCaption}{" "}
          {currentImage.entity.fieldCredit && (
            <span>&copy;&nbsp;{currentImage.entity.fieldCredit}</span>
          )}
        </p>
      </div>
    </>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
  globalCaption: PropTypes.string,
};

export default ImageSlider;
