import React, { useEffect, useRef, useState } from "react";
import Landingpage from "./landingpage";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const Home = () => {
  const history = useHistory();
  const workRef = useRef();
  const [isWorkVisible, setIsWorkVisible] = useState(false);
  const {locale} = useIntl()

  const prefix = locale === "en" ? "/en" : ""

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsWorkVisible(true);
          observer.disconnect();
          window.history.pushState(prefix + "/work", 1, prefix + "/work");
          window.scrollTo(0, 0);
        }
      },
      {
        root: null,
        rootMargin: "0px 0px -100% 0px", // Beobachten Sie, wenn das Element bei (0, 0) eindockt
        threshold: 0.0,
      }
    );

    if (workRef.current) {
      observer.observe(workRef.current);
    }

    return () => {
      if (workRef.current) {
        observer.disconnect(workRef.current);
      }
    };
  }, [history, workRef]);

  return (
    <div>
      {!isWorkVisible && (
        <div id="home-page">
          <Landingpage />
        </div>
      )}
      <div id="work-page" ref={workRef}>
        <Landingpage pathname={"/work"} />
      </div>
    </div>
  );
};

export default Home;
