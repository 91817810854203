import { useFormikContext } from "formik";
import useDebouncedEffect from "../../../../../hooks/use-debounced-effect";

/**
 * @see https://github.com/jaredpalmer/formik/issues/2769
 *
 * @returns {null}
 */
const AutoSubmit = ({ isMobile }) => {
  /*
    This component is used to automatically submit the form when the form is valid
    and has been changed(dirty).
   */
  const { isValid, values, dirty, submitForm } = useFormikContext();

  useDebouncedEffect(
    () => {
      if (isValid && !isMobile) {
        void submitForm();
      }
    },
    [isValid, values, dirty, submitForm],
    250
  );

  return null;
};

export default AutoSubmit;
