import React, { useState } from "react";
import { Form, Formik } from "formik";
import AutoSubmit from "./auto-submit";
import { useQuery } from "@apollo/client";
import QueryExtendedTeaserViewFilters from "./query-filters.graphql";

// Filters.
import StringFilter from "./filters/string";
import TaxonomyFilter from "./filters/taxonomy";
import useOverviewContext from "../store/use-overview-context";
import classNames from "classnames";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import useLockBodyScroll, {
  LockBodyScroll,
} from "../../../../../hooks/useLockBodyScroll";
import SubmitOnClose from "./submit-on-close";

/**
 * Renders a set of filters for a specific view.
 *
 * @param {Object} props - The component props.
 * @param {string} props.viewId - The ID of the view.
 * @param {Function} props.onChange - The callback function to handle filter changes.
 * @returns {JSX.Element|null} The rendered Filters component.
 */
const Filters = ({ filterOpen, closeFilters }) => {
  const { viewId, currentFilters, setCurrentFilters } =
    useOverviewContext();

  // Get exposed filters.
  const { data } = useQuery(QueryExtendedTeaserViewFilters, {
    variables: {
      viewId,
    },
  });

  const isMobile = useMediaQuery({ query: "(max-width: 1199px)" });

  // Get active exposed filters and exclude the bundle filter.
  const defaultBundleFilter = (item) => {
    return item.pluginId === "bundle" && item.field === "type";
  };
  const filters =
    data?.entityById.executable.filters?.filter(
      (filter) => filter.isExposed && !defaultBundleFilter(filter)
    ) || [];

  // Get initialValues for views exposed form.
  const initialValues = {};
  filters.forEach((filter) => {
    initialValues[filter.options.id] = currentFilters[filter.options.id]
      ? currentFilters[filter.options.id]
      : filter.options.value;
  });

  // Fetch nodes when filters change and reset page to 0.
  const filterChangeHandler = (values) => {
    if (_.isEqual(values, currentFilters)) return;
    document.getElementById("overview")?.scrollIntoView({ behavior: "smooth" });
    setCurrentFilters(values);
  };

  // If no filters are exposed, return null.
  if (!filters.length) return null;

  return (
    <>
      {filters.length > 0 && (
        <AnimatePresence>
          {((filterOpen && isMobile) || !isMobile) && (
            <motion.div
              className="filter-wrapper"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {filterOpen && <LockBodyScroll />}
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  filterChangeHandler(values);
                }}
              >
                <Form>
                  {/* @todo Only use <Autosubmit> for text fields, feels weird for e.g. selects */}
                  <div className="filters">
                    <AutoSubmit isMobile={isMobile} />
                    <SubmitOnClose />
                    {filters.map((item, index) => (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (item.pluginId) {
                            case "string":
                            case "combine":
                              return (
                                <StringFilter
                                  item={item}
                                  onEnter={closeFilters}
                                />
                              );
                            case "taxonomy_index_tid":
                              return <TaxonomyFilter item={item} />;
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    ))}
                  </div>
                </Form>
              </Formik>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default Filters;
