import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

const joinFields = (fields, joiner) => fields.filter(Boolean).join(joiner);

const ProjectFactsheet = ({ content }) => {
  const intl = useIntl();

  const facts = {
    location: content.fieldCity,
    competition_year: content.fieldCompetition?.value.split("-")[0],
    competition_ranking: content.fieldCompetitionRanking,
    planning: joinFields([
      content.fieldPlanning?.value?.split("-")[0],
      content.fieldPlanning?.endValue?.split("-")[0],
    ], " - "),
    market_launch: content.fieldMarketLaunch?.value.split("-")[0],
    start_of_construction:
      content.fieldStartOfConstruction?.value.split("-")[0],
    completion: content.fieldCompletion?.value.split("-")[0],
    builder: content.fieldBauherr?.name,
    client: content.fieldClientWebsite
      ? `<a
        target="_blank"
        rel="noreferrer"
        href=${content.fieldClientWebsite.uri.path}
      >
        ${content.fieldClient[0]?.name}
      </a>`
      : content.fieldClient[0]?.name,
    gfa: content.fieldGfa
      ? intl.formatMessage({ id: "unit.sqm" }, { sqm: content.fieldGfa })
      : false,
    awards: content.fieldAwards,
    certificates: content.fieldCertificates,
    project_management: content.fieldProjectManagement,
    project_team: content.fieldProjectTeam,
    image_copyright: content.fieldImageCopyright
  };

/*   const sorting = [
    facts.location,
    facts.compoetition_year,
    facts.competition_ranking,
    facts.planning,
    facts.market_launch,
    facts.start_of_construction,
    facts.completion,
    facts.builder,
    facts.client,
    facts.gfa,
    facts.awards,
    facts.certificates,
    facts.project_management,
    facts.project_team,
    facts.copyright,
  ] */

  /**
   * Generate an array out of the above facts object.
   * Filter out items, that are not set.
   */
  const factsFormatted = Object.entries(facts)
    .map((item) => ({
      labelId: item[0],
      value: item[1],
    }))
    .filter((item) => !!item.value);

  // Return nothing if no facts are set.
  if (!factsFormatted.length) return;

  return (
    <section className="paragraph paragraph-project-factsheet">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h2 className="headline-l">
              <FormattedMessage id="project_factsheet" />
            </h2>
          </div>
          <div className="col-12 col-lg-6 facts">
            {factsFormatted.map(({ labelId, value }) => (
              <div key={labelId} className="fact-wrapper">
                <span className="fact-label">
                  <FormattedMessage id={`project_factsheet.${labelId}`} />
                </span>
                <span
                  className="fact-value"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectFactsheet.propTypes = {
  content: PropTypes.shape({
    fieldAwards: PropTypes.string,
    fieldStartOfConstruction: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldGfa: PropTypes.number,
    fieldFacadeConsultant: PropTypes.string,
    fieldCompletion: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldBuildingServices: PropTypes.string,
    fieldProjectTeam: PropTypes.string,
    fieldCity: PropTypes.string,
    fieldPlanning: PropTypes.string,
    fieldProjectManagement: PropTypes.string,
    fieldStructuralEngineering: PropTypes.string,
    fieldCompetition: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldCompetitionRanking: PropTypes.string,
    fieldCertificates: PropTypes.string,
    fieldBauherr: PropTypes.shape({
      name: PropTypes.string,
    }),
    fieldRegion: PropTypes.shape({
      name: PropTypes.string,
    }),
    fieldMarketLaunch: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldClient: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    fieldClientShort: PropTypes.string,
    fieldClientWebsite: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ProjectFactsheet;
