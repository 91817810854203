import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import YouTubeVideo from "./component-youtube";
import VimeoVideo from "./component-vimeo";
import ComponentVideo from "./component-video";
import ErrorBoundary from "../../../../error-boundary";

/**
 * Render YouTube, Vimeo or local video files.
 */
class ParagraphMedia extends Component {
  render() {
    const { content } = this.props;
    const sectionClassNames = classNames({
      "paragraph paragraph-media": true,
      "paragraph-media--youtube": content.fieldMediaArt === "youtube",
      "paragraph-media--vimeo": content.fieldMediaArt === "vimeo",
      "paragraph-media--video-file": content.fieldMediaArt === "video_file",
    });

    switch (content.fieldMediaArt) {
      case "youtube":
        return (
          <ErrorBoundary>
            <YouTubeVideo
              content={content}
              sectionClassNames={sectionClassNames}
            />
          </ErrorBoundary>
        );
      case "vimeo":
        return (
          <ErrorBoundary>
            <VimeoVideo
              content={content}
              sectionClassNames={sectionClassNames}
            />
          </ErrorBoundary>
        );
      case "video_file":
        return (
          <ErrorBoundary>
            <ComponentVideo
              content={content}
              sectionClassNames={sectionClassNames}
            />
          </ErrorBoundary>
        );
      default:
        return false;
    }
  }
}

ParagraphMedia.propTypes = {
  content: PropTypes.shape({
    fieldMediaArt: PropTypes.oneOf(["youtube", "vimeo", "video_file"])
      .isRequired,
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVimeoVideoUrl: PropTypes.string,
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string,
    }),
    fieldVideoFileMedia: PropTypes.shape({
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphMedia;
