import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import ImageSlider from "../../image-slider/image-slider";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserNewsTimeline = (props) => {
  const [expanded, setExpanded] = useState(false);
  const htgReveal = keyframes`
      from {
        opacity: 0;
        transform: translateY(50px);
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
    `;

  return (
    <Reveal triggerOnce keyframes={htgReveal}>
      <article className="node node-teaser teaser-news teaser-news-timeline">
        <EditButton
          adminApp={props.adminApp}
          entityId={props.item.id}
          destinationRoute={props.location.pathname}
        />
        <div className="row">
          <div className="col-12 col-lg-6">
            {props.item.fieldBildWCaptionRawField?.list && (
              <ImageSlider images={props.item.fieldBildWCaptionRawField.list} />
            )}
          </div>
          <div className="col-12 col-lg-6">
            <h3 className="headline-m">{props.item.title}</h3>
            {props.item.fieldSubtitle && (
              <h4 className="headline-m headline-secondary">
                {props.item.fieldSubtitle}
              </h4>
            )}
            <p className="headline-m headline-secondary">
              <FieldTime
                timestamp={true}
                date={
                  props.item.publishedAtRawField?.first.value ||
                  props.item.publishedAtRawField?.first.publishedAtOrNow
                }
                format={"DD. MMMM YYYY"}
              />
            </p>
            <div
              className={classNames({
                "news-body": true,
                expanded: expanded,
              })}
            >
              {props.item.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: props.item.fieldText,
                  }}
                />
              )}
              
              {props.item.fieldBody && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: props.item.fieldBody,
                  }}
                />
              )}
            </div>
            {!expanded && (
              <button
                className="btn btn-secondary"
                onClick={() => setExpanded(true)}
              >
                <FormattedMessage id="read_more" />
              </button>
            )}
          </div>
        </div>
      </article>
    </Reveal>
  );
};

TeaserNewsTimeline.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNewsTimeline));
