import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import inMemoryCache from "../cache";

const LanguageSwitch = (props) => {
  const i18n = useSelector((reduxStore) => reduxStore.i18n);
  const history = useHistory();

  const translationUrl = i18n.translations.filter((t) => !t.active)[0]?.url
    ?.path;

  const toggleLanguage = () => {
    inMemoryCache.reset();
    props.closeMenu ? props.closeMenu : null;
    history.push(translationUrl, { background: null });
  };

  return (
    <div
      className={classNames({
        "language-switch": true,
        active: !!translationUrl,
        [props.className]: !!props.className,
      })}
    >
      <svg
        width="56"
        height="26"
        viewBox="0 0 56 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={toggleLanguage}
      >
        <path
          d="M13 13L43 13"
          stroke="#cfcfcf"
          strokeWidth="25"
          strokeLinecap="round"
        />
        <path
          d="M13 13L43 13"
          className={`active-path ${
            i18n.currentLanguage === "en" ? "active" : ""
          }`}
          stroke="black"
          strokeWidth="25"
          strokeLinecap="round"
        />
        <g style={{ mixBlendMode: "difference" }}>
          <path
            d="M10 18V8H14.4773C18.4235 8 20.093 10.2308 20.093 12.9359C20.093 15.9103 18.0567 18 14.6165 18H10ZM11.9731 16.2436H14.4267C16.3619 16.2436 18.0314 15.5128 18.0314 13.0385C18.0314 10.1667 15.8939 9.75641 13.9841 9.75641H11.9731C11.9731 11.859 11.9731 14.141 11.9731 16.2436Z"
            fill="white"
            id="de"
          />
          <path
            d="M38 18V8H45.6646V9.75641H39.9731C39.9731 10.5 39.9731 11.2564 39.9731 12H45.3611V13.8462H39.9731C39.9731 14.641 39.9731 15.4359 39.9731 16.2308H45.8164V18H38Z"
            fill="white"
            id="en"
          />
        </g>
      </svg>
      {/* 
          <Link
            to={
              this.props.i18n.currentContentGermanTranslation
                ? this.props.i18n.currentContentGermanTranslation
                : "#"
            }
            className={classNames({
              active: this.props.i18n.currentLanguage === "de",
              "not-translated":
                !this.props.i18n.currentContentGermanTranslation,
            })}
          >
            DE
          </Link>

          <Link
            to={
              this.props.i18n.currentContentEnglishTranslation
                ? this.props.i18n.currentContentEnglishTranslation
                : "#"
            }
            className={classNames({
              active: this.props.i18n.currentLanguage === "en",
              "not-translated":
                !this.props.i18n.currentContentEnglishTranslation,
            })}
          >
            EN
          </Link> */}
    </div>
  );
};

LanguageSwitch.propTypes = {
  closeMenu: PropTypes.func,
};

export default LanguageSwitch;
